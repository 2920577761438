export const menuItems = [
    {
        id: 0,
        label: "menu.menuitems.reports.text",
        icon: "bx bxs-report",
        link: '/reports'
    },
    {
        id: 1,
        label: "menu.menuitems.inventories.text",
        icon: "bx bxs-package",
        link: '/inventories'
    },
    {
        id: 1,
        label: "menu.menuitems.areas.text",
        icon: "bx bxs-map",
        link: '/areas'
    },
    {
        id: 1,
        label: "menu.menuitems.delivery_men.text",
        icon: "bx bxs-truck",
        link: '/delivery-men'
    },
    {
        id: 1,
        label: "menu.menuitems.orders.text",
        icon: "bx bxs-cart",
        link: '/orders'
    },
    {
        id: 1,
        label: "menu.menuitems.users.text",
        icon: "bx bxs-user",
        link: '/users'
    }
];
